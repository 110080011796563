import React, { useState } from "react"
import { Link } from "gatsby";
import { useForm } from "react-hook-form";
import { pages } from '../../site-config'


export const ContactForm = ({ title, summary, image, buttonText }) => {     
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [state, setState] = useState({
        isSubmitted: false,
        isError: false
      });    

    const onSubmit = (data) => {
        fetch('https://us-central1-outsmart-production.cloudfunctions.net/contactEnquiry', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(response => {
            setState({
                isSubmitted: true,
            });
        })
        .catch(err => {
            setState({
                isError: true,
            });
        });
    }

    return (
        <div className="c-contact">
            <div className="container">
                <div className="row">
                    <div className="c-contact__media-container">
                        <img src={image !== null ? image : "/images/test/contact.png"} alt="" className="c-contact__media" loading="lazy" />
                    </div>
                    <div className="c-contact__form">
                        {title ? 
                            <h2 className="c-contact__title">{title}</h2> : null}
                        {summary ? 
                            <p className="c-contact__summary">{summary}</p> : null}
                        {!state.isSubmitted ? 
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <fieldset>
                                    <legend className="c-form__legend">Contact</legend>
                                    <div className="c-contact__form-fields">
                                        <div className="c-contact__form-field">
                                            <div className="c-form__textbox-field">
                                                <label htmlFor="fullname" className="c-form__label">Name</label>
                                                <input id="fullname" name="fullname" placeholder="Full Name" className={errors.fullname ? "c-form__textbox error" : "c-form__textbox"} {...register("fullname", { required: true })} />
                                                {errors.fullname && <div className="c-form__validation c-form__validation--error"><p>Please enter your name</p></div>}
                                            </div>
                                        </div>
                                        <div className="c-contact__form-field c-contact__form-field--half">
                                            <div className="c-form__textbox-field">
                                                <label htmlFor="company" className="c-form__label">Company Name</label>
                                                <input id="company" name="field[1]" placeholder="Company" className={errors.company ? "c-form__textbox error" : "c-form__textbox"} {...register("company", { required: true })} />
                                                {errors.company && <div className="c-form__validation c-form__validation--error"><p>Please enter a company name</p></div>}
                                            </div>
                                        </div>
                                        <div className="c-contact__form-field c-contact__form-field--half">
                                            <div className="c-form__textbox-field">
                                                <label htmlFor="position" className="c-form__label">Position</label>
                                                <input id="position" name="field[12]" placeholder="Position" className={errors.position ? "c-form__textbox error" : "c-form__textbox"} {...register("position", { required: true })} />
                                                {errors.position && <div className="c-form__validation c-form__validation--error"><p>Please enter a position</p></div>}
                                            </div>
                                        </div>
                                        <div className="c-contact__form-field">
                                            <div className="c-form__textarea-field">
                                                <label htmlFor="contactmessage" className="c-form__label">Message</label>
                                                <textarea rows="3" id="contactmessage" name="field[11]" placeholder="Message" className={errors.contactmessage ? "c-form__textarea error" : "c-form__textarea"} {...register("contactmessage", { required: true })}></textarea>
                                                {errors.contactmessage && <div className="c-form__validation c-form__validation--error"><p>Please enter your message</p></div>}
                                            </div>
                                        </div>
                                        <div className="c-contact__form-field">
                                            <div className="c-form__textbox-field">
                                                <label htmlFor="email" className="c-form__label">Email</label>
                                                <input id="email" name="email" placeholder="email@company.com" className={errors.contactemail ? "c-form__textbox error" : "c-form__textbox"} {...register("email", { required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/ })} />
                                                {errors.email && <div className="c-form__validation c-form__validation--error"><p>Please enter a valid email</p></div>}
                                            </div>
                                        </div>
                                        <div className="c-contact__form-field">
                                            <p className="c-contact__form-consent">By clicking the 'Send Message' button you're confirming that you agree with our following <Link to={pages.privacyPolicy}>Privacy Policy</Link>.</p>
                                        </div>
                                        {buttonText ? 
                                        <div className="c-contact__form-field">
                                            <input type="submit" value={buttonText} className="c-contact__submit c-btn c-btn--large c-btn--primary" />
                                        </div> : null}
                                    </div>
                                </fieldset>
                                {state.isError ? <p className="c-contact__summary">Unfortunately, your submission could not be sent. Please try again later.</p> : null}    
                            </form>
                            : <p className="c-contact__summary">Thank you for contacting us. We will be in touch shortly.</p>}
                        <h3 className="c-contact__subtitle">Get in touch</h3>
                        <p className="c-contact__summary">
                            <a href="mailto:hello@outsmartinsight.com">hello@outsmartinsight.com</a>
                            <a href="tel:+443330020240">+44 333 002 0240</a>
                        </p>
                        <h3 className="c-contact__subtitle">Looking for job or consulting?</h3>
                        <p className="c-contact__summary">
                            <a href="mailto:join@outsmartinsight.com">join@outsmartinsight.com</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactForm;