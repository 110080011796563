
import * as React from 'react'

export const Masthead = ({subtitle, title, summary, image}) => {    
    return (
        <div className={`c-masthead ${image ? "c-masthead--media" : ""}`}>
            <div className="container">
                <div className="row">
                    <div className="c-masthead__container">
                        {subtitle ? <div className="c-masthead__subtitle">{subtitle}</div> : null}                        
                        <h1 className="c-masthead__title">{title}</h1>
                        {summary ? <p className="c-masthead__summary">{summary}</p> : null}                        
                    </div>
                    {image && (
                        <div className="c-masthead__media-container">
                            <img src={`${image}&w=1400`} alt="" className="c-masthead__media" loading="lazy" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
  }

export default Masthead